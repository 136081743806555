/** @jsx jsx */
import { jsx, Box, Grid, Heading, Text, Button } from 'theme-ui'
import { Fragment } from 'react'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { formatPrice } from 'utils'
import { NearbyGrouped, Rooms, SEO } from 'components'

function Room(props) {
  const { data } = props

  const { room, roomImage, doubleRoom } = data

  return (
    <Fragment>
      <SEO title={room.name} />

      <Box bg="black.5" px={['m']}>
        <Grid
          columns={[null, null, '3fr 5fr']}
          gap={['xl', null, 'xxl']}
          pt={['xl', 'xxl', 'xxxxl']}
          pb={[null, null, 'xxxxl']}
          variant="boxes.container"
        >
          <Box my="auto">
            <Heading variant="heading.xxl" mb="l">
              {room.name}
            </Heading>

            {room.bedTypes && (
              <Text
                variant="stnd.xxs"
                mb="l"
                py="s"
                sx={{
                  borderTop: 'solid 1px',
                  borderBottom: 'solid 1px',
                  borderColor: 'black.20',
                }}
              >
                {room.bedTypes.join(' or ')} • {room.roomType.shortName}
              </Text>
            )}

            <Text variant="stnd.body" mb="m">
              {formatPrice(room.nightlyCost)} / Night
            </Text>

            <Box pt="xs">
              <Button sx={{ width: 150 }}>Book now</Button>
            </Box>
          </Box>
          <Grid
            sx={{
              boxShadow: 'l',
              borderRadius: 5,
            }}
          >
            <GatsbyImage
              fluid={roomImage.childImageSharp.fluid}
              sx={{ borderRadius: 5 }}
              style={{ display: 'block' }}
            />
          </Grid>
        </Grid>

        <Grid
          variant="boxes.container"
          mx="auto"
          gap={['xl', 'xxl', 'xxxl']}
          columns={[null, null, '1fr 1fr']}
          py={['xl', 'xxl', 'xxxl']}
        >
          <Box
            sx={{
              boxShadow: 'l',
              borderRadius: 5,
              order: [2, null, 1],
            }}
          >
            <GatsbyImage
              fluid={doubleRoom.childImageSharp.fluid}
              sx={{ borderRadius: 5 }}
              style={{ display: 'block' }}
            />
          </Box>
          <Box my="auto" sx={{ order: [1, null, 2] }}>
            <Text as="p">
              En-suite twin bedroom, description of the room goes here. Aenean
              eu leo quam. Pellentesque ornare sem lacinia quam venenatis
              vestibulum. Etiam porta sem malesuada magna mollis euismod.
              Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
              auctor.
            </Text>
          </Box>
        </Grid>
      </Box>

      <Grid
        columns={[null, null, '1fr 1px 1fr']}
        gap={['m', 'l', 'xxl']}
        variant="boxes.container"
        mx="auto"
        py={['xl', null, 'xxxl']}
        px={['m']}
      >
        <Box>
          <Text
            mb="l"
            variant="stnd.xs"
            sx={{ fontWeight: 'semibold', color: 'orange.dark' }}
          >
            Amenities
          </Text>
          <Grid columns="1fr 1fr" gap="s">
            <Box>
              <Box>– Free parking</Box>
              <Box>– Free WiFi</Box>
              <Box>– Indoor fireplace</Box>
              <Box>– Pet friendly</Box>
            </Box>
            <Box>
              <Box>– Washing machine</Box>
              <Box>– Iron</Box>
              <Box>– Hair dryer</Box>
              <Box>– TV</Box>
            </Box>
          </Grid>
        </Box>
        <Box
          sx={{
            borderLeft: 'solid 1px',
            borderColor: 'black.5',
            display: ['none', null, 'block'],
          }}
        />
        <Box>
          <Text
            mb="l"
            variant="stnd.xs"
            sx={{ fontWeight: 'semibold', color: 'orange.dark' }}
          >
            House rules
          </Text>
          <Box>– Check in from 1pm</Box>
          <Box>– Check out before 12 noon</Box>
          <Box>– No smoking</Box>
          <Box>– Pets are allowed</Box>
        </Box>
      </Grid>

      <NearbyGrouped />

      <Box
        pt={['xl', 'xxl', 'xxxxl']}
        pb={['xl', 'xxl', 'xxxxxl']}
        px={'m'}
        variant="boxes.measure"
        mx="auto"
        sx={{ textAlign: 'center' }}
      >
        <Heading variant="heading.m" mb="m" sx={{ color: 'orange.dark' }}>
          Things to know
        </Heading>
        <Text as="p" mb="xxl">
          This is a very rural location at the top of a peaceful valley in a
          farming community. The nearest town Ingleton is 6 miles away.
        </Text>
        <Text variant="subhead" mb="m" sx={{ color: 'orange.dark' }}>
          Cancellation policy
        </Text>
        <Text as="p">
          Free cancellation may be available for this stay. Add your trip dates
          to get the details.
        </Text>
      </Box>

      <Box bg="black.5" pt={['xl', 'xxl', 'xxxl']} pb={['xl', 'xxl', 'xxxxxl']}>
        <Box variant="boxes.container" mx="auto" mb={['l', 'xl', 'xxl']}>
          <Heading variant="heading.l" sx={{ textAlign: 'center' }}>
            You might also like
          </Heading>
        </Box>
        <Box px="m">
          <Rooms count={3} />
        </Box>

        <Box pt="xxl">
          <Text variant="subhead" sx={{ textAlign: 'center' }}>
            View all rooms
          </Text>
        </Box>
      </Box>
    </Fragment>
  )
}

export const roomQuery = graphql`
  query ContentfulRoomQuery($id: String!) {
    room: contentfulRoom(id: { eq: $id }) {
      ...RoomFragment
      roomType {
        id
        shortName
      }
    }
    roomImage: file(relativePath: { eq: "home-room@2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    doubleRoom: file(relativePath: { eq: "south-facing@2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Room
